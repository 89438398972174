import { LOCAL } from '@/core/constants';
import { uiHelper } from '@/core/utils';

export default function constantsKey(header, value) {
  let description;
  let newArray = [];

  if (header && !['', undefined, null].includes(value)) {
    if (Array.isArray(value)) {
      if (value.length > 0) {
        value.forEach((x) => {
          newArray.push(getKey(header, x));
        });
        description = newArray.join(', ');
      } else {
        description = '-';
      }
    } else {
      description = getKey(header, value);
    }
  } else {
    description = '-';
  }

  return description;
}

function getKey(header, value) {
  let constantsData = JSON.parse(uiHelper.getLocalStorage(LOCAL.CONSTANTS));

  return constantsData[header].find((x) => x.value == value).key;
}
