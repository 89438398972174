<template>
  <PageLayout
    bannerSrc="/assets/image/sign-up_success-background.jpg"
    :pageTitle="$t('label.registerComplete')"
    :pageSubtitle="$t('label.letsExperienceCookingTogether')"
    pageTitleDividerClass="primary"
    color="surface"
    layoutType="b"
    :hideLogo="true"
    class="register-complete__page-layout"
  >
    <div
      class="content-wrapper d-flex align-center register-complete__content-wrapper"
    >
      <v-row no-gutters align="center" class="wh-100">
        <v-col cols="12" md="5"
          ><div class="font-weight-bold text-center surface--text text-h5">
            {{ $t('label.scanAndDownload') }}
          </div>
          <div class="d-flex justify-center ma-8">
            <qr-code
              :size="qrCodeSize"
              :text="qrCodeURL"
              class="qr-code"
            ></qr-code>
          </div>
          <v-row no-gutters class="px-12">
            <template v-for="(i, index) in appsIcons">
              <v-col
                :key="index"
                cols="4"
                :class="[
                  { 'pr-2': index == 0 },
                  { 'px-1': index == 1 },
                  { 'pl-2': index == 2 }
                ]"
              >
                <img
                  :src="i.src"
                  class="max-w-100 cursor-pointer"
                  @click="getAppsDownloadLink(i)"
                />
              </v-col>
            </template> </v-row
        ></v-col>
      </v-row>
    </div>
  </PageLayout>
</template>

<script>
  import PageLayout from '@/views/layout/PageLayout';
  import constantsKey from '@/core/filters/constants-key.filter';
  import { uiHelper } from '@/core/utils';
  import { DEVICE_TYPE } from '@/core/constants/enums';
  import { ROUTE_NAME } from '@/core/constants';
  import {
    LOOKUP_GET_GLOBAL_SETTING,
    LOOKUP_INITIAL_GET_GLOBAL_SETTING_STATE
  } from '@/core/store/lookup.module';

  export default {
    name: 'RegisterComplete',
    components: {
      PageLayout
    },
    data: () => ({
      appsIcons: [
        {
          src: '/assets/image/google-play-store.png',
          key: DEVICE_TYPE.ANDROID
        },
        {
          src: '/assets/image/apple-app-store.png',
          key: DEVICE_TYPE.IOS
        },
        {
          src: '/assets/image/huawei-app-gallery.png',
          key: DEVICE_TYPE.HUAWEI
        }
      ]
    }),
    computed: {
      qrCodeSize() {
        let size = 130;

        if (this.$vuetify.breakpoint.lgAndUp) {
          size = 160;
        } else if (this.$vuetify.breakpoint.mdOnly) {
          size = 140;
        } else if (this.$vuetify.breakpoint.smAndDown) {
          size = 120;
        }

        return size;
      },
      qrCodeURL() {
        let location = window.location;

        return `${location.protocol}${location.hostname}/${ROUTE_NAME.QR_LINK}`;
      },
      globalSettingComplete() {
        return this.$store.state.lookup.globalSetting.complete;
      }
    },
    watch: {
      globalSettingComplete() {
        let response = this.$store.state.lookup.globalSetting;

        if (response.complete) {
          this.globalSettingCompleteAction(response);
        }
      }
    },
    methods: {
      globalSettingCompleteAction(response) {
        if (response.code == 0) {
          uiHelper.openPage(response.data.url, '_self');
        } else {
          console.log(response);
        }

        this.initialGetGlobalSettingState();
      },
      getAppsDownloadLink(value) {
        let platformKey = constantsKey('deviceType', value.key);
        let data = {
          platform: platformKey
        };

        this.getGlobalSetting(data);
      },
      getGlobalSetting(data) {
        this.$store.dispatch(LOOKUP_GET_GLOBAL_SETTING, { data });
      },
      initialGetGlobalSettingState() {
        this.$store.dispatch(LOOKUP_INITIAL_GET_GLOBAL_SETTING_STATE);
      }
    }
  };
</script>

<style lang="scss">
  .register-complete__page-layout {
    background-position: bottom !important;
    .page-layout__divider-2 {
      border-color: rgba(255, 255, 255, 0.6) !important;
    }
    .register-complete__content-wrapper {
      min-height: 750px;
      .qr-code {
        border: 35px solid var(--v-primary-base);
        border-radius: 25px;
      }
    }
  }

  @media (max-width: 959px) {
    .register-complete__page-layout {
      .register-complete__content-wrapper {
        min-height: 550px;
        .qr-code {
          border: 25px solid var(--v-primary-base) !important;
          border-radius: 25px;
        }
      }
    }
  }
</style>
